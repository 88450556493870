import { TradeRegistryOwners } from '../../types';

const MAX_COMPANY_REPRESENTATIVES = 2;

const filterByPosition = (owners: TradeRegistryOwners[], position: string) => {
  return owners.filter((owner) => owner.position === position).slice(0, MAX_COMPANY_REPRESENTATIVES);
};

export const getRepresentatives = (companyType: string, owners: TradeRegistryOwners[]) => {
  switch (companyType) {
    case 'Фондация':
    case 'Народно Читалище':
    case 'Сдружение':
    case 'СД':
    case 'ЕАД':
    case 'АД':
      return filterByPosition(owners, 'Представители');
    case 'ЕТ':
      return filterByPosition(owners, 'Физическо лице - търговец');
    case 'Кооперация':
      return filterByPosition(owners, 'Председател');
    default:
      return filterByPosition(owners, 'Управители');
  }
};
